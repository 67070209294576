import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import useResizeAware from 'react-resize-aware';
import styled from 'styled-components';

import { brand } from '@constants/colors';
import { space } from '@constants/spaces';

const Top = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Bottom = styled.div<{ height: number | null; open: boolean }>`
  margin-left: auto;
  width: calc(100% - 20px); // icon
  height: ${(props) =>
    props.height ? (props.open ? `${props.height}px` : 0) : 'auto'};
  overflow: hidden;
  will-change: height;
  transition: height 300ms;
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${brand};
  margin-right: ${space}px;
  width: 12px;
`;

type AccordionProps = {
  defaultOpen?: boolean;
  children: React.ReactNode[];
  iconOpen?: IconProp;
  iconClosed?: IconProp;
};

const Accordion = ({
  defaultOpen,
  children,
  iconOpen,
  iconClosed,
}: AccordionProps) => {
  const [open, setOpen] = useState(defaultOpen || false);
  const [resizeListener, sizes] = useResizeAware();
  const components = React.Children.toArray(children);

  return (
    <div>
      <Top onClick={() => setOpen(!open)}>
        <Icon
          icon={open ? iconOpen || faChevronUp : iconClosed || faChevronDown}
        />
        {components[0]}
      </Top>
      <Bottom open={open} height={sizes.height}>
        <Wrapper>
          {components[1]}
          {resizeListener}
        </Wrapper>
      </Bottom>
    </div>
  );
};

export default Accordion;
