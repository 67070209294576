import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { cta, ctaOutline } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { exampleBooks } from '@constants/routes';
import { textWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Text from '@elements/Text';
import Title from '@elements/Title';

import TwoColumn from './TwoColumn';

const Content = styled.div`
  width: 100%;
  > * {
    max-width: ${textWidth};
    margin: 0 auto;
  }

  ${tablet} {
    text-align: left;
    > * {
      margin: 0;
    }
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;

  ${tablet} {
    justify-content: left;
  }
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;

  svg {
    margin-left: ${space}px;
  }

  &:hover {
    color: ${ctaOutline};
  }
`;

const Example = () => {
  return (
    <TwoColumn
      alignment="center"
      leftColumn={
        <Content>
          <Title size="lg" as="h3" className="mb-24">
            Met een schat aan voorbeelden en ideeën
          </Title>
          <Text weight={400} size="nm" className="mb-24">
            Een fotoboek vol verhalen, herinneringen en grappige anekdotes. Hoe
            pak je dat aan? Om je op weg te helpen, hebben wij een hele
            bibliotheek vol vragen, coole stickers, weetjes, tips en meer.
            <br />
            <br />
            Plus talloze voorbeelden van enthousiastelingen die jou voorgingen.
            Wedden dat je ineens bruist van de inspiratie?
          </Text>
          <Link href={exampleBooks} passHref>
            <StyledLink>
              Voorbeelden bekijken
              <FontAwesomeIcon icon={faArrowRightLong} />
            </StyledLink>
          </Link>
        </Content>
      }
      rightColumn={
        <ImgContainer>
          <Image
            src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/spreads_voorbeeld_J0wxuY4Ni.png?updatedAt=1716379665136&ik-s=5e928770179f9bc37a62243adc607ebe2f8c7bdb"
            alt="Simpel samenwerken"
            width={560}
            height={380}
            unoptimized={true}
            loading="lazy"
          />
        </ImgContainer>
      }
    />
  );
};

export default Example;
