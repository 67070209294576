import Image from 'next/image';
import styled from 'styled-components';

import { tablet } from '@constants/media-queries';
import { textWidth } from '@constants/sizes';
import Text from '@elements/Text';
import Title from '@elements/Title';

import TwoColumn from './TwoColumn';

const Content = styled.div`
  width: 100%;
  > * {
    max-width: ${textWidth};
    margin: 0 auto;
  }

  ${tablet} {
    text-align: left;
    > * {
      margin: 0;
    }
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;

  ${tablet} {
    justify-content: left;
  }
`;

const Gift = () => {
  return (
    <TwoColumn
      alignment="center"
      reverseOnMobile={true}
      leftColumn={
        <ImgContainer>
          <Image
            src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/belmondofoto_gift_I-lBLGx4h.png?updatedAt=1715956301746&ik-s=b4ffade4b80ad1e673de95220e63926d13c3ea04"
            alt="Belmondo fotoboek als cadeau"
            width={560}
            height={380}
            unoptimized={true}
            loading="lazy"
          />
        </ImgContainer>
      }
      rightColumn={
        <Content>
          <Title size="lg" as="h3" className="mb-24">
            Een cadeau om nooit meer te vergeten
          </Title>
          <Text weight={400} size="nm">
            Samen het leven vieren, verhalen vertellen en herinneringen
            koesteren. En gewoon eindelijk eens zwart op wit zeggen hoeveel je
            om iemand geeft.
            <br />
            <br />
            Een boek van Belmondo is gegarandeerd een schot in de roos.
            Schitterend uitgevoerd, persoonlijk en uniek.
          </Text>
        </Content>
      }
    />
  );
};

export default Gift;
