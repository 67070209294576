import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { fontDark } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { bookChoice } from '@constants/routes';
import { contentWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Text from '@elements/Text';
import Title from '@elements/Title';

const Component = styled.section`
  padding: ${space * 5}px ${space * 4}px;
  text-align: center;
  color: ${fontDark};

  ${tablet} {
    padding: ${space * 8}px ${space * 4}px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
`;

const ImgContainer = styled.div`
  flex-basis: 100%;
  ${tablet} {
    flex-basis: 50%;
  }
`;

type EditorExampleProps = {
  buttonLink?: string;
  buttonText?: string;
};

const EditorExample = ({ buttonLink, buttonText }: EditorExampleProps) => {
  return (
    <Component>
      <Container>
        <Title size="lg" weight={800} className="mb-8" as="h2">
          Precies zoals jij het wilt
        </Title>
        <Text size="nm" className="mb-32">
          Omslag, titel, achtergronden, tekst, stickers: pas alles in het boek
          aan naar wens!
        </Text>
        <ImgContainer className="mb-32">
          <Image
            src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/editor_example_9yCHarTS_.png?updatedAt=1715950922673&ik-s=dfd62a1365081937099cbc802e06b77c2645a0b0"
            alt="Editor voorbeeld"
            width={980}
            height={620}
            unoptimized={true}
            loading="lazy"
          />
        </ImgContainer>
        <Link href={buttonLink || bookChoice} passHref>
          <a>
            <Button iconRight={faArrowRightLong} size="big">
              {buttonText || 'Begin nu'}
            </Button>
          </a>
        </Link>
      </Container>
    </Component>
  );
};

export default EditorExample;
