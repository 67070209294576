import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { googleReviews } from '@constants/routes';

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ReviewsRating = () => {
  return (
    <Link href={googleReviews} passHref>
      <a target="_blank" rel="noreferrer">
        <ImgContainer>
          <Image
            src="/images/reviews/reviews_stars.png"
            alt="Google reviews"
            width={120}
            height={23}
          />
        </ImgContainer>
      </a>
    </Link>
  );
};

export default ReviewsRating;
