import Image from 'next/image';
import styled from 'styled-components';

import { tablet } from '@constants/media-queries';
import { textWidth } from '@constants/sizes';
import Text from '@elements/Text';
import Title from '@elements/Title';

import TwoColumn from './TwoColumn';

const Content = styled.div`
  width: 100%;
  > * {
    max-width: ${textWidth};
    margin: 0 auto;
  }

  ${tablet} {
    text-align: left;
    > * {
      margin: 0;
    }
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;

  ${tablet} {
    justify-content: left;
  }
`;

const Collaborate = () => {
  return (
    <TwoColumn
      alignment="center"
      leftColumn={
        <Content>
          <Title size="lg" as="h3" className="mb-24">
            Simpel samenwerken met Belmondo
          </Title>
          <Text weight={400} size="nm">
            Zware programma&apos;s downloaden of apps installeren? Nergens voor
            nodig! Bij Belmondo kun je direct aan de slag met je eigen fotoboek.{' '}
            <br />
            <br />
            Kopieer de link en deel deze eenvoudig met wie je maar wilt. Via
            e-mail, Messenger of WhatsApp.{' '}
          </Text>
        </Content>
      }
      rightColumn={
        <ImgContainer>
          <Image
            src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/collaborate_users_HkycIiFz1.png?updatedAt=1715950556182&ik-s=e310d30666ce51ef2da5403055d365e51d3486ac"
            alt="Simpel samenwerken"
            width={560}
            height={380}
            unoptimized={true}
            loading="lazy"
          />
        </ImgContainer>
      }
    />
  );
};

export default Collaborate;
