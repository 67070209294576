import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { laptop, tablet } from '@constants/media-queries';
import { containerWidth } from '@constants/sizes';
import { space } from '@constants/spaces';

const Container = styled.div<{
  paddingTopMobile: number;
  paddingBotMobile: number;
}>`
  display: flex;
  padding-top: ${({ paddingTopMobile }) => `${paddingTopMobile}px`};
  padding-bottom: ${({ paddingBotMobile }) => `${paddingBotMobile}px`};
  padding-left: ${space * 3}px;
  padding-right: ${space * 3}px;
  align-content: stretch;
  overflow: hidden;

  ${tablet} {
    justify-content: center;
    padding: ${space * 6}px ${space * 5}px;
  }

  ${laptop} {
    padding: ${space * 8}px ${space * 5}px;
  }
`;

const Row = styled.div<{ reverseOnMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ reverseOnMobile }) =>
    reverseOnMobile ? 'column-reverse' : 'column'};
  width: 100%;
  gap: ${space * 5}px;

  ${tablet} {
    flex-direction: row;
    width: ${containerWidth};
  }
`;

const Column = styled.div<{ alignment: string; wide: boolean }>`
  flex: 1;
  display: flex;
  align-items: ${({ alignment }) => {
    if (alignment === 'left') return 'flex-start';
    if (alignment === 'right') return 'flex-end';
    return 'center';
  }};

  ${tablet} {
    flex: ${({ wide }) => (wide ? '3' : '2')};
    width: calc(${({ wide }) => (wide ? '60%' : '40%')} - ${space * 2}px);
  }
`;

type Props = {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
  alignment?: 'top' | 'bottom' | 'center';
  wideColumnLeft?: boolean;
  wideColumnRight?: boolean;
  reverseOnMobile?: boolean;
  paddingTopMobile?: number;
  paddingBotMobile?: number;
};

const TwoColumn = ({
  leftColumn,
  rightColumn,
  alignment = 'center',
  wideColumnLeft = false,
  wideColumnRight = false,
  reverseOnMobile = false,
  paddingTopMobile = space * 5,
  paddingBotMobile = space * 5,
}: Props) => {
  return (
    <Container
      paddingTopMobile={paddingTopMobile}
      paddingBotMobile={paddingBotMobile}
    >
      <Row reverseOnMobile={reverseOnMobile}>
        <Column alignment={alignment} wide={wideColumnLeft}>
          {leftColumn}
        </Column>
        <Column alignment={alignment} wide={wideColumnRight}>
          {rightColumn}
        </Column>
      </Row>
    </Container>
  );
};

export default TwoColumn;
